import { combineReducers } from "redux";
// import Ecommerce from "./ecommerce/product/reducer";
// import Filters from "./ecommerce/filter/reducer";
// import Wishlist from "./ecommerce/wishlist/reducer";
// import Cart from "./ecommerce/cart/reducer";
import Customizer from "./customizer/reducer";
const reducers = combineReducers({
  // data: Ecommerce,
  // filters: Filters,
  // Wishlistdata: Wishlist,
  // Cartdata: Cart,
  Customizer,
});

export default reducers;
