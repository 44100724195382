// import ForgotPassword from "../auth/ForgotPassword/ForgotPassword";
// import NewPassword from "../auth/ForgotPassword/NewPassword";
// import Otp from "../auth/ForgotPassword/otp";
// import BusinessAddress from "../auth/Register/CompanyDetails/BusinessAddress";
// import ComapnyDetails from "../auth/Register/CompanyDetails/ComapnyDetails";
// import Register from "../auth/Register/Register";
import Signin from "../auth/signin";
// Authentication

export const authRoutes = [
  { path: `${process.env.PUBLIC_URL}/login`, Component: <Signin /> },
  // { path: `${process.env.PUBLIC_URL}/register`, Component: <Register /> },
  // {
  //   path: `${process.env.PUBLIC_URL}/company-form`,
  //   Component: <ComapnyDetails />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/company-address`,
  //   Component: <BusinessAddress />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/ForgotPassword`,
  //   Component: <ForgotPassword />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/get-otp`,
  //   Component: <Otp />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/new-password`,
  //   Component: <NewPassword />,
  // },
];
