// dashbaord
// import Ecommerce from "../components/dashboard/ecommerce";
// import Users from "../components/users/users";
// import Organization from "../components/organization/organization";
// import UserProfile from "../components/userProfile/userProfile";
// import GenratedQrAndNfc from "../components/organization/genratedQrAndNfc";
// import GeneratedNfc from "../components/organization/generatedNfc";
// import LeadMaster from "../components/LeadMaster/LeadMaster";
// import LeadSourceMaster from "../components/LeadSourceMaster/LeadSourceMaster";
// import DefaultSetting from "../components/DefaultSetting/DefaultSetting";
// import Emplyess from "../components/Emplyees/Emplyess";
// import AddEmployee from "../components/Emplyees/AddEmployee";
// import Categories from "../components/Categories/Categories";
// import Products from "../components/Products/Products";
// import AddProducts from "../components/Products/AddProducts";
// import Leads from "../components/Leads/Leads";
// import AddLead from "../components/Leads/AddLead";
// import CustomeField from "../components/CustomeField/CustomeField";
// import AddFollowUp from "../components/Leads/AddFollowUp";
// import Customers from "../components/Customers/Customers";
// import AddCustomer from "../components/Customers/AddCustomer";
// import EmployeeProfile from "../components/Emplyees/EmployeeProfile";
// import ProductDetail from "../components/Products/ProdcutDetail";
// import Followups from "../components/Followups/Followups";
// import CompletedFollowups from "../components/Followups/CompletedFollowups";
// import Invoices from "../components/Invoices/Invoices";
// import AddInvoice from "../components/Invoices/AddInvoice";

import Ecommerce from "../components/dashboard/ecommerce";


export const routes = [
  
  {
    path: `${process.env.PUBLIC_URL}/dashboard/`,
    Component: <Ecommerce />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/:layout/`,
  //   Component: <Ecommerce />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/LeadMaster/LeadMaster/:layout/`,
  //   Component: <LeadMaster />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/LeadSourceMaster/LeadSourceMaster/:layout/`,
  //   Component: <LeadSourceMaster />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/DefaultSetting/DefaultSetting/:layout/`,
  //   Component: <DefaultSetting />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/CustomeField/CustomeField/:layout/`,
  //   Component: <CustomeField />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Emplyees/Emplyess/:layout/`,
  //   Component: <Emplyess />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Emplyees/AddEmployee/:layout/`,
  //   Component: <AddEmployee />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Emplyees/EmployeeProfile/:layout/`,
  //   Component: <EmployeeProfile />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Categories/Categories/:layout/`,
  //   Component: <Categories />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Products/Products/:layout/`,
  //   Component: <Products />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Products/ProductDetail/:layout/`,
  //   Component: <ProductDetail />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Products/AddProducts/:layout/`,
  //   Component: <AddProducts />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/users/users/:layout/`,
  //   Component: <Users />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/organization/organization/:layout/`,
  //   Component: <Organization />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/organization/genratedQrAndNfc/:layout/`,
  //   Component: <GenratedQrAndNfc />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/organization/generatedNfc/:layout/`,
  //   Component: <GeneratedNfc />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/userProfile/userProfile/:layout/`,
  //   Component: <UserProfile />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Leads/Leads/:layout/`,
  //   Component: <Leads />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Leads/AddLead/:layout/`,
  //   Component: <AddLead />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Customers/Customers/:layout/`,
  //   Component: <Customers />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Customers/AddCustomer/:layout/`,
  //   Component: <AddCustomer />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Leads/AddFollowUp/:layout/`,
  //   Component: <AddFollowUp />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Followups/CompletedFollowups/:layout/`,
  //   Component: <CompletedFollowups />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Invoices/Invoices/:layout/`,
  //   Component: <Invoices />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Invoices/AddInvoice/:layout/`,
  //   Component: <AddInvoice />,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/Followups/Followups/:layout/`,
  //   Component: <Followups />,
  // },
];
