export const SERVICES = {
  BYTEQR: "/byte-qr",
  _PUBLIC: "/byte-qr/public",
  GETWAY: "/gateway/admin",
  WHITE_LABEL_DOMAIN: "/api/whiteLabeledData",
};

export const V1 = "/api/v1";
export const api = "/api";

/** LOGIN */

export const LOGIN_ = `${api}/loginAdmin`;
export const REGISTER_ = `${api}/register`;
export const COMPLETE_BUSINESS_PROFILE__ = `${api}/completeBusinessProfile`;
export const BUSINESS_CATEGORIES_ = `${api}/searchActiveBusinessCategories`;

/** LOGIN */

/** FORGOT PASSWORD */
export const SEND_PASSWORD_OTP = `${api}/sendForgotPasswordOtp`;
export const OTP_VARIFICATION = `${api}/VerifyForgotPasswordOtp`;
export const FORGOT_PASSWORD = `${api}/forgotPassword`;
/** FORGOT PASSWORD */

/** DASHBOARD*/
export const LEADS_ = `${api}/leads/countWithStatus`;
export const ALL_CONFIGURATION = `${api}/rest/configurations/all`;
export const SELLER_DESHBOARD = `${SERVICES.BYTEQR}${V1}/seller_dashboard/detail`;
export const NFC__LIST_STATUS_UPDATE = `${SERVICES.BYTEQR}${V1}/nfc_order/status`;
export const NFC_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/nfc_order/generated-nfc-detail`;
export const NFC_ORDER_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/nfc_order/detail`;
export const NFC_ORDER_LIST_TITLE_UPDATE = `${SERVICES.BYTEQR}${V1}/nfc_order`;
export const ADD_PUNCH = `${api}/punches/add`;
/** DASHBOARD*/

/**LEAD LIST */
export const LEAD_ADD = `${api}/configurations/leadStatus/add`;
export const LEAD_UPDATE = `${api}/configurations/leadStatus/update`;
export const LEAD_DELETE = `${api}/configurations/leadStatus/delete`;
export const LEAD_DRAG_AND_DROP = `${api}/rest/leads/status/sequence/update`;
/**LEAD LIST */

/**LEAD SOURCE LIST */
export const LEAD_SOURCE_ADD = `${api}/configurations/leadSource/add`;
export const LEAD_SOURCE_UPDATE = `${api}/configurations/leadSource/update`;
export const LEAD_SOURCE_DELETE = `${api}/configurations/leadSource/delete`;
/**LEAD SOURCE LIST */

/**CUSTOME FIELDS LIST */
export const CUSTOME_FIELDS_LIST = `${api}/rest/customfields/all`;
export const DELETE_CUSTOME_FIELDS = `${api}/rest/customfields/delete`;
export const CUSTOME_FIELDS_ADD = `${api}/rest/customfields/add`;
export const CUSTOME_FIELDS_EDIT = `${api}/rest/customfields/update`;
/**CUSTOME FIELDS LIST */

/**EOMPOYEE */
export const EMPLOYEE_LIST = `${api}/rest/employees/search`;
export const Add_EMPLOYEE = `${api}/employee/add`;
export const EDIT_EMPLOYEE = `${api}/rest/employee/update`;
export const DELETE_EMPLOYEE = `${api}/rest/employee/delete`;
export const CHECK_EMAIL = `${api}/checkEmail`;
export const EMPLOYEE_ACTIVE_INACTIVE = `${api}/rest/employee/updateStatus`;
export const EMPLOYEE_IMAGE_UPLOADE = `${api}/uploadEmployeeImage`;
export const EMPLOYEE_PROFILE = `${api}/rest/employees/get`;
/**EOMPOYEE */

/**LEADS */
export const LEADS_LIST = `${api}/rest/leads/search`;
export const LEADS_ADD = `${api}/rest/leads/add`;
export const DELETE_LEAD = `${api}/rest/leads/delete`;
export const GET_LEAD = `${api}/rest/leads/get`;
export const LEAD_EDIT = `${api}/rest/leads/update`;
export const FILTER_LEADS = `${api}/leads/filter`;
/**LEADS */

/**CUSTOMERS */
export const CUSTOMERS_LIST = `${api}/rest/customers/search`;
export const CUSTOMERS_ADD = `${api}/rest/customers/add`;
export const DELETE_CUSTOMER = `${api}/rest/customers/delete`;
export const GET_CUSTOMER = `${api}/rest/customers/get`;
export const CUSTOMER_EDIT = `${api}/rest/customers/update`;
export const FILTER_CUSTOMERS = `${api}/customers/filter`;
/**CUSTOMERS */

/**INVOICES */
export const INVOICES_LIST = `${api}/rest/invoices/search`;
export const NEXT_INVOICE_NO = `${api}/invoice/getNextInvoiceNo`;
export const INVOICE_ADD = `${api}/rest/invoices/add`;
export const DELETE_INVOICE = `${api}/rest/invoices/delete`;
export const GET_INVOICE = `${api}/rest/invoices/search`;
export const INVOICE_EDIT = `${api}/rest/invoices/update`;
export const INVOICE_FILTER = `${api}/invoice/filter`;
/**CUSTOMERS */

/**FOLLOW UPS */
export const FOLLOWUP_LIST = `${api}/rest/followups/search`
export const FOLLOWUP_ADD = `${api}/rest/followups/add`;
export const FOLLOWUP_RESCHEDULE = `${api}/rest/followup/reschedule`;
export const FOLLOWUP_START = `${api}/rest/followup/meeting/start`;
export const FOLLOWUP_END = `${api}/rest/followup/meeting/end`;
export const FOLLOWUP_COMPLETE = `${api}/rest/followup/complete`;
export const FOLLOWUP_UPDATE = `${api}/rest/followups/update`;
export const FOLLOWUP_DELETE = `${api}/rest/followups/delete`;
export const FILTER_FOLLOWUPS = `${api}/followups/filter`;
/**FOLLOW UPS */

/**CATEGORIES */
export const CATEGORIES_LIST = `${api}/rest/productCategories/all`;
export const Add_CATEGORIES = `${api}/rest/productCategory/add`;
export const EDIT_CATEGORIES = `${api}/rest/productCategory/update`;
export const DELETE_CATEGORIES = `${api}/rest/productCategory/delete`;
/**CATEGORIES */

/**PRODUCTS */
export const PRODUCTS_LIST = `${api}/rest/products/search`;
export const PRODUCTS_ACTIVE_INACTIVE = `${api}/rest/products/updateStatus`;
export const Add_PRODUCTS = `${api}/rest/products/add`;
export const EDIT_PRODUCTS = `${api}/rest/products/update`;
export const DELETE_PRODUCTS = `${api}/rest/products/delete`;
export const PRODCUTS_IMAGE_UPLOADE = `${api}/uploadProductImage`;
/**PRODUCTS */

/**CHECK CONSTRAINTS & CHANGE PASSWORD */
export const CHNAGEPASSWORD = "api/changePassword"; 
/**CHECK CONSTRAINTS & CHANGE PASSWORD */

/** USER_LIST */

export const USER_LIST = `${SERVICES.BYTEQR}${V1}/user`;

/** USER_LIST */

/** ORGANIZATION*/
export const ORG_LIST = `${SERVICES.BYTEQR}${V1}/organization`;
export const ADD_ORG = `${SERVICES.BYTEQR}${V1}/organization`;
export const ADD_ORG_BALANCE = `${SERVICES.BYTEQR}${V1}/organization/addOrganizationBalance`;
export const ORG_DITAILS = `${SERVICES.BYTEQR}${V1}/organization/detail`;
export const ORG_PROFILE = `${SERVICES.BYTEQR}${V1}/organization/profile`;
export const EDIT_ORG = `${SERVICES.BYTEQR}${V1}/organization`;
export const ORG_ENABLE_DESABLE = `${SERVICES.BYTEQR}${V1}/organization/enableDisable`;
export const FILE = `${SERVICES._PUBLIC}${V1}/file`;
/** ORGANIZATION*/

/** PACKAGES*/
export const PACKAGES_LIST = `${SERVICES.BYTEQR}${V1}/package_master`;
export const ADD_PACKAGES = `${SERVICES.BYTEQR}${V1}/package_master`;
export const EDIT_PACHAGES = `${SERVICES.BYTEQR}${V1}/package_master`;
export const PACKAGE_DETAILS = `${SERVICES.BYTEQR}${V1}/package_master/detail`;
/** PACKAGES*/

/** SHIPPING MASTER*/
export const SHIPPING_LIST = `${SERVICES.BYTEQR}${V1}/shipping_company_master`;
export const SHIPPING_ADD = `${SERVICES.BYTEQR}${V1}/shipping_company_master`;
export const SHIPPING_DETAILS = `${SERVICES.BYTEQR}${V1}/shipping_company_master/detail`;
export const EDIT_SHIPPING = `${SERVICES.BYTEQR}${V1}/shipping_company_master`;
/** SHIPPING MASTER*/

/** ORDER STATUS LIST*/
export const ORDER_STATUS_LIST = `${SERVICES.BYTEQR}${V1}/order_status_master`;
export const ADD_ORDER_STATUS = `${SERVICES.BYTEQR}${V1}/order_status_master`;
export const ORDER_STATUS_DETAILS = `${SERVICES.BYTEQR}${V1}/order_status_master/detail`;
export const EDIT_ORDER_STATUS = `${SERVICES.BYTEQR}${V1}/order_status_master`;
/** ORDER STATUS LIST*/

/** QR ORDER*/
export const QR_LIST = `${SERVICES.BYTEQR}${V1}/qr_order`;
export const QR_REGUNRATE = `${SERVICES.BYTEQR}${V1}/qr_order/re-generate-order`;
export const CREATE_QR = `${SERVICES.BYTEQR}${V1}/qr_order`;
export const ALL_QR = `${SERVICES.BYTEQR}${V1}/qr_order/generated-qr-list`;
export const OR_ORDER_LIST_TITLE_UPDATE = `${SERVICES.BYTEQR}${V1}/qr_order`;
export const QR_ORDER_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/qr_order/detail`;
/** QR ORDER*/

/** NFC ORDER*/
export const NFC_ORDER = `${SERVICES.BYTEQR}${V1}/nfc_order`;
export const NFC_LIST = `${SERVICES.BYTEQR}${V1}/nfc_order/generated-nfc-list`;
export const CREATE_NFC = `${SERVICES.BYTEQR}${V1}/nfc_order`;
export const NFC_REGENARATE = `${SERVICES.BYTEQR}${V1}/nfc_order/re-generate-order`;
/** NFC ORDER*/

/** SELLER PASSWORD CHANGE */
export const PASSWORD_CHANGE = `${SERVICES.BYTEQR}${V1}/user/change-password`;
/** SELLER PASSWORD CHANGE */

/** DOWNLOAD QR  */
export const DOWNLOAD_QR = `${SERVICES.BYTEQR}${V1}/qr_order/qrGeneratedDetails`;
export const DOWNLOAD_NFC = `${SERVICES.BYTEQR}${V1}/nfc_order/nfcGeneratedDetails`;
/** DOWNLOAD QR  */

/** PRIVILLAGE MASTER LIST*/
export const PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const ADD_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const EDIT_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DELETE_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DETAILS_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege/detail`;
export const MODULE_ACCESS_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/access`;
/** PRIVILLAGE MASTER LIST*/

/** MODUAL MASTER LIST*/
export const MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const ADD_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DETAILS_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/detail`;
export const EDIT_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DELETE_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
/** MODUAL MASTER LIST*/

/**WHITE LABEL DOMAIN API */
export const GET_WHITE_LABEL_DOMAIN = `${SERVICES.WHITE_LABEL_DOMAIN}/getAll`;
export const POST_CREATE_WHITE_LABEL_DOMAIN = `${SERVICES.WHITE_LABEL_DOMAIN}/add`;
export const PUT_EDIT_WHITE_LABEL_DOMAIN = `${SERVICES.WHITE_LABEL_DOMAIN}/update`;
/**WHITE LABEL DOMAIN API */
