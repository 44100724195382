/**
 *
 * @param {string} length
 * @returns rendome string
 */
export const randomStr = (length) => {
  let randomText = "";
  const randomString =
    "ABCDEFGHIJKLMNPPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
  for (let i = 0; i < length; i++) {
    randomText += randomString.charAt(
      Math.floor(Math.random() * randomString.length)
    );
  }
  return randomText;
};

/**
 *
 * @param {dacimal color dormate} d
 * @param {*} padding
 * @returns decimal to hex string formate color
 */

export function decimalToHexString(d, padding) {
  let hex = Number(d).toString(16);
  padding =
    typeof padding === "undefined" || padding === null
      ? (padding = 2)
      : padding;

  while (hex.length < padding) {
    hex = "0" + hex;
  }

  return "#" + hex;
}

/**
 *
 * @param {hex color} s
 * @returns hex color formate to vb color formate
 */
export function HEXToVBColor(s) {
  function add(xVal, yVal) {
    let c = 0,
      r = [];
    let x = xVal.split("").map(Number);
    let y = yVal.split("").map(Number);
    while (x.length || y.length) {
      let s = (x.pop() || 0) + (y.pop() || 0) + c;
      r.unshift(s < 10 ? s : s - 10);
      c = s < 10 ? 0 : 1;
    }
    if (c) r.unshift(c);
    return r.join("");
  }

  let dec = "0";
  s.split("").forEach(function (chr) {
    let n = parseInt(chr, 16);
    for (let t = 8; t; t >>= 1) {
      dec = add(dec, dec);
      if (n & t) dec = add(dec, "1");
    }
  });
  return dec;
}

/**
 *
 * @param {number of price} num
 * @returns indian currency formate
 */

export const toIndianCurrency = (num) => {
  const curr = num.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
  return curr;
};

/**
 * uuid generater
 * @returns uuid
 */

export const UuidGenerater = () => {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

/**
 * Max length for fild number type helper
 */

export const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};

/**
 * Mouse weel to stop increase and decrease number
 */

//onWheel Event
export const StopIncreaseAndDecrease = (event) => {
  event.target.blur();
};

//onKeyDown Event
export const UparrowAndDownarrow = (event) => {
  if (event.key === "ArrowUp" || event.key === "ArrowDown") {
    event.preventDefault();
  }
};
