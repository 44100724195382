import {
  Home,
  Users,
  BarChart,
  GitCommit,
  Server,
  Clipboard,
  Box,
  Settings,
  FileText,
  List,
  Monitor,
} from "react-feather";
export const MENUITEMS = [
  {
    menutitle: "Hello, " + localStorage.getItem("first_name") + " " +localStorage.getItem("last_name"),

    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        title: "Products",
        icon: Box,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/list-products`,
            title: "Product list",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/product-page`,
            title: "Add New Product",
            type: "link",
          },
        ],
      },
      {
        title: "Product Inquiery",
        icon: Monitor,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/inquiries-list`,
            title: "Inquiery List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bulk-orders`,
            title: "Bulk Orders",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/china-order`,
            title: "China Orders",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/ask-for-rate`,
            title: "Ask For Rates",
            type: "link",
          },
          
        ],
      },
      {
        path: `${process.env.PUBLIC_URL}/LeadMaster/LeadMaster`,
        icon: BarChart,
        title: "Lead Status Master",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/LeadSourceMaster/LeadSourceMaster`,
        icon: GitCommit,
        title: "Lead Source Master",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/DefaultSetting/DefaultSetting`,
        icon: Server,
        title: "Default Setting",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/CustomeField/CustomeField`,
        icon: Settings,
        title: "Custome Fields Master",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Leads/Leads`,
        icon: Clipboard,
        title: "Leads",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Customers/Customers`,
        icon: Users,
        title: "Customers",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Followups/Followups`,
        icon: FileText,
        title: "Followups",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Emplyees/Emplyess`,
        icon: Users,
        title: "Employees",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Invoices/Invoices`,
        icon: FileText,
        title: "Invoices",
        type: "link",
      },
      {
        title: "Products & Categories",
        icon: Box,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/Products/Products`,
            title: "Products",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Categories/Categories`,
            title: "Categories",
            type: "link",
          },
        ],
      },
    ],
  },
];

export const SELLER_MENUITEMS = [
  {
    menutitle: "Hello, " + localStorage.getItem("first_name"),

    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/LeadMaster/LeadMaster`,
        icon: BarChart,
        title: "Lead Status Master",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/LeadSourceMaster/LeadSourceMaster`,
        icon: GitCommit,
        title: "Lead Source Master",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/DefaultSetting/DefaultSetting`,
        icon: Server,
        title: "Default Setting",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Leads/Leads`,
        icon: Clipboard,
        title: "Leads",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Emplyees/Emplyess`,
        icon: Users,
        title: "Employees",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Customers/Customers`,
        icon: Clipboard,
        title: "Customers",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/Followups/Followups`,
        icon: FileText,
        title: "Followups",
        type: "link",
      },
      {
        title: "Products & Categories",
        icon: Box,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/Products/Products`,
            title: "Products",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Categories/Categories`,
            title: "Categories",
            type: "link",
          },
        ],
      },
    ],
  },
];
