import { Provider } from "react-redux";
import "./App.css";
import LoaderState from "./context/LoaderContext/LoaderState";
import Routers from "./route";
import store from "./store";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <LoaderState>
          <Routers />
        </LoaderState>
      </Provider>
    </div>
  );
}

export default App;
