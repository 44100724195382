import axios from "axios";
import {
  BUSINESS_CATEGORIES_,
  COMPLETE_BUSINESS_PROFILE__,
  LOGIN_,
  REGISTER_,
} from "../../constant/API constant";

export const Login = (formData) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${LOGIN_}`,
    formData
  );
};

export const RedisterApi = (formData) => {
  return axios.post(
    `${process.env.REACT_APP_AJ_ANANT_API_URL}${REGISTER_}`,
    formData
  );
};

export const CompleBusinessProfile = (formData, tokenStore) => {
  const jwt_token = localStorage.getItem("token") || tokenStore;
  return axios.post(
    `${process.env.REACT_APP_AJ_ANANT_API_URL}${COMPLETE_BUSINESS_PROFILE__}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const getBusinessCategory = () => {
  return axios.get(
    `${process.env.REACT_APP_AJ_ANANT_API_URL}${BUSINESS_CATEGORIES_}`
  );
};
